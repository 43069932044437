import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./_all.scss";

const Faq = () => {
  const [tab, setTab] = useState("");
  const [question, setQuestion] = useState("");

  const submitEvent = (e) => {
    e.preventDefault();
    setQuestion("");
    toast(`Submitted successfully`, {
      style: {
        background: "#f4f0e2",
        color: "#f2510d",
      },
    });
  };
  const toggleCard = (index) => {
    if (tab === index) {
      setTab("");
    } else {
      setTab(index);
    }
  };
  return (
    <div className="faq-section">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center mb-5">
            <div className="section-title">FAQ</div>
            <div className="faq-section-title">Frequently Asked Questions</div>
            <form className="faq-form" onSubmit={submitEvent}>
              <input
                type="text"
                placeholder="Enter your question"
                required
                className="faq-field"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <button type="submit" className="faq-btn">
                Send
              </button>
            </form>
          </div>
          {data.map((val, index) => {
            return (
              <div className="col-12" key={index}>
                <div className="faq-card" onClick={() => toggleCard(index)}>
                  <div className={`faq-question ${tab === index && "active"}`}>
                    {val.question}
                    <i className="fa-solid fa-plus"></i>
                  </div>
                  {tab === index && (
                    <div className="faq-answer">{val.answer}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const data = [
  {
    question: "What types of loans do you offer?",
    answer:
      "We offer a variety of loans to meet your needs, including personal loans, home loans, car loans, and business loans. Our goal is to provide financial solutions tailored to your specific requirements.",
  },
  {
    question: "How do I apply for a loan?",
    answer:
      "Applying for a loan is easy! Simply visit our website and fill out the online application form. You'll need to provide some basic information about yourself and your financial situation. Once submitted, our team will review your application and get in touch with you.",
  },
  {
    question: "What are the eligibility criteria for getting a loan?",
    answer:
      "Eligibility criteria may vary depending on the type of loan you are applying for. Generally, factors such as your credit score, income, employment history, and other financial details are taken into consideration. Visit our eligibility page for specific information.",
  },
  {
    question: "How are interest rates determined?",
    answer:
      "Interest rates are determined based on various factors, including your credit score, loan amount, and the type of loan. We strive to offer competitive rates to our customers. You can check our interest rates page for detailed information.",
  },
  {
    question: "Can I manage my loan account online?",
    answer:
      "Yes, you can manage your loan account conveniently through our online portal. This includes making payments, checking your outstanding balance, and accessing important documents related to your loan.",
  },
];

export default Faq;
