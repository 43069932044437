import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import GetLoan from "../../components/get-loan";
import Faq from "../../components/faq";
import "./_all.scss";

const ContactUs = () => {
  const [data, setdata] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setdata({ ...data, [name]: value });
  };
  const submitEvent = (e) => {
    e.preventDefault();
    toast(`Form submitted successfully ${data.name}`, {
      style: {
        background: "#f4f0e2",
        color: "#f2510d",
      },
    });
    setdata({
      name: "",
      email: "",
      number: "",
      subject: "",
      message: "",
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contact-us">
      <ToastContainer hideProgressBar={true} />
      {/* Contact Banner Section Start */}
      <div className="page-top-section">
        <div className="page-pagination">
          <NavLink to="/" className="pagination-item">
            Home
          </NavLink>
          /
          <NavLink to="/contact-us" className="pagination-item active">
            Contact us
          </NavLink>
        </div>
        <div className="page-title">Contact Us</div>
      </div>
      {/* Contact Banner Section End */}

      {/* Contact Detail Section Start */}
      <section className="contact-details">
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-lg-4 order-lg-1 order-2">
              <div className="contact-details-info">
                <div className="contact-details-info-left">
                  <i className="fa-solid fa-location-dot"></i>
                </div>
                <div className="contact-details-info-right">
                  <div className="contact-details-info-title">Head Office</div>
                  <div className="contact-details-info-desc">
                    Akshya Nagar 1st Block 1st Cross, Rammurthy nagar,
                    Bangalore-560016
                  </div>
                </div>
              </div>

              <div className="contact-details-info">
                <div className="contact-details-info-left">
                  <i className="fa-solid fa-clock"></i>
                </div>
                <div className="contact-details-info-right">
                  <div className="contact-details-info-title">
                    Working Hours
                  </div>
                  <div className="contact-details-info-desc">
                    Mon - Fri: 10AM - 07PM <br />
                    Sat - Sun: Closed
                  </div>
                </div>
              </div>

              <div className="contact-details-info">
                <div className="contact-details-info-left">
                  <i className="fa-solid fa-envelope"></i>
                </div>
                <div className="contact-details-info-right">
                  <div className="contact-details-info-title">EMAIL</div>
                  <div className="contact-details-info-desc">
                    tfhsales@info.com
                  </div>
                </div>
              </div>

              <div className="contact-details-info">
                <div className="contact-details-info-left">
                  <i className="fa-solid fa-phone-volume"></i>
                </div>
                <div className="contact-details-info-right">
                  <div className="contact-details-info-title">Phone</div>
                  <div className="contact-details-info-desc">
                    +0000 000 00000 <br />
                    +1234 0000 9876
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-lg-2 0rder-1 mb-lg-0 mb-5">
              <div className="get-in-touch">Get in Touch</div>
              <div className="contact-desc">
                We'd love to hear from you! Whether you have a question about
                our products, need assistance, or just want to say hello, we're
                here for you. Choose the most convenient way to reach out, and
                we'll get back to you as soon as possible.
              </div>
              <form className="contact-us-form" onSubmit={submitEvent}>
                <input
                  type="text"
                  className="input-field"
                  placeholder="Enter you name"
                  name="name"
                  value={data.name}
                  onChange={inputEvent}
                  required
                />
                <input
                  type="email"
                  className="input-field"
                  placeholder="Enter your email"
                  name="email"
                  value={data.email}
                  onChange={inputEvent}
                  required
                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="Enter your mobile number"
                  name="number"
                  value={data.number}
                  onChange={inputEvent}
                  required
                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="Subject"
                  name="subject"
                  value={data.subject}
                  onChange={inputEvent}
                  required
                />
                <textarea
                  type="text"
                  rows="5"
                  className="input-field"
                  placeholder="Message"
                  name="message"
                  value={data.message}
                  onChange={inputEvent}
                  required
                />
                <button type="submit" className="contact-btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Detail Section End */}

      {/* Faq Section Start */}
      <div className="">
        <Faq />
      </div>
      {/* Faq Section End */}

      {/* Get Loan Section Start */}
      <div className="mt-5">
        <GetLoan />
      </div>
      {/* Get Loan Section End */}
    </div>
  );
};

export default ContactUs;
