import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Faq from "../../components/faq";
import GetLoan from "../../components/get-loan";
import Testimonials from "../../components/testimonials";
import Customer from "../../assets/customer.jpg";
import LoanApply from "../../components/loan-apply";
import "./_all.scss";

const Loan = () => {
  const [formVisible, setformVisible] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="loan-page">
      <LoanApply formVisible={formVisible} setformVisible={setformVisible} />
      {/* Contact Banner Section Start */}
      <div className="page-top-section">
        <div className="page-pagination">
          <NavLink to="/" className="pagination-item">
            Home
            <i className="fa-solid fa-angle-right"></i>
          </NavLink>
          /
          <NavLink to="" className="pagination-item active">
            {id.replace("-", " ")}
            <i className="fa-solid fa-angle-right"></i>
          </NavLink>
        </div>
        <div className="page-title">{id.replace("-", " ").toUpperCase()}</div>
      </div>
      {/* Contact Banner Section End */}

      <section className="loan-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 d-none d-xl-block">
              <div className="navigation-menu">
                <NavLink
                  to="/loan/home-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "home-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Home Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
                <NavLink
                  to="/loan/wedding-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "wedding-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Wedding Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
                <NavLink
                  to="/loan/car-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "car-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Car Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
                <NavLink
                  to="/loan/business-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "business-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Bussiness Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
                <NavLink
                  to="/loan/education-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "education-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Education Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
                <NavLink
                  to="/loan/personal-loan"
                  className={`navigation-item ${
                    id.replace("-", " ") === "personal-loan" && "active"
                  }`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Personal Loan
                  <i className="fa-solid fa-angle-right"></i>
                </NavLink>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="happy-customer-layout">
                <img src={Customer} className="happy-customer" alt="customer" />
                <div className="happy-customer-percent">
                  <span>98% </span>
                  Happy Customers
                </div>
              </div>
              <div className="loan-desc">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure
                obcaecati similique exercitationem, maxime eligendi sequi. Sit a
                voluptas, quos possimus dicta quo placeat et! Nesciunt, sequi
                iusto, est at ad doloremque maxime nobis excepturi veritatis
                eveniet cum! Sed, quidem eius?
              </div>
              <div className="loan-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Nesciunt non voluptas modi facilis eaque eos quod ex placeat
                nemo nobis asperiores commodi nihil omnis, amet consequatur
                inventore corrupti. Expedita excepturi veritatis vel, tempore
                fugiat temporibus pariatur repudiandae cupiditate, nulla
                perferendis, corporis impedit deserunt non quam. Blanditiis
                provident et doloribus ea!
              </div>
              <div className="loan-desc">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure
                obcaecati similique exercitationem, maxime eligendi sequi. Sit a
                voluptas, quos possimus dicta quo placeat et! Nesciunt, sequi
                iusto, est at ad doloremque maxime nobis excepturi veritatis
                eveniet cum! Sed, quidem eius?
              </div>
              <ul className="loan-desc-list">
                <li className="loan-desc-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                    ex iste cumque perferendis officia hic saepe amet ab sunt.
                    Et!
                  </div>
                </li>
                <li className="loan-desc-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Ipsa, quis?
                  </div>
                </li>
                <li className="loan-desc-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea
                    earum quam dolor. Cumque odit delectus iusto. Harum
                    reiciendis nam, cumque quod necessitatibus quidem adipisci
                    distinctio expedita unde dolores, modi hic.
                  </div>
                </li>
                <li className="loan-desc-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea
                    earum quam dolor. Cumque odit delectus iusto. Harum
                    reiciendis nam, cumque quod necessitatibus quidem adipisci
                    distinctio expedita unde dolores, modi hic.
                  </div>
                </li>
              </ul>
              <div
                className="loan-contact-btn"
                onClick={() => setformVisible(true)}
              >
                Quick Apply For Loan
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section Start */}
      <Testimonials />
      {/* Testimonials Section End */}

      {/* Faq Section Start */}
      <div className="">
        <Faq />
      </div>
      {/* Faq Section End */}

      {/* Get Loan Section Start */}
      <div className="mt-5">
        <GetLoan />
      </div>
      {/* Get Loan Section End */}
    </div>
  );
};

export default Loan;
