import React, { useState } from "react";
import LoanApply from "../loan-apply";
import "./_all.scss";

const GetLoan = () => {
  const [formVisible, setformVisible] = useState(false);
  return (
    <section className="get-loan">
      <LoanApply formVisible={formVisible} setformVisible={setformVisible} />
      <div className="container">
        <div className="row">
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="get-loan-right">
              <div className="get-loan-content">
                <div className="get-loan-pagination">
                  <div className="loan-pagination-item">Simple</div>/
                  <div className="loan-pagination-item">Transparent</div>/
                  <div className="loan-pagination-item">Secured</div>
                </div>
                <div className="get-loan-title">Get quick business loans</div>
                <div className="get-loan-btn" onClick={() => setformVisible(true)}>Apply For Loan</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetLoan;
