import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AboutIllustration from "../../assets/about.jpg";
import AboutIcon from "../../assets/about-loan.svg";
import GetLoan from "../../components/get-loan";
import Testimonials from "../../components/testimonials";
import Img1 from "../../assets/offerings/fast-application.jpg";
import Img2 from "../../assets/offerings/competitive-interest.jpg";
import Img3 from "../../assets/offerings/flexible-loan.jpg";
import Img4 from "../../assets/offerings/secure.jpg";
import Img5 from "../../assets/offerings/customer-support.jpg";
import Img6 from "../../assets/offerings/financial-education.jpg";
import "./_all.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const images = [Img1, Img2, Img3, Img4, Img5, Img6];
  return (
    <div className="about-page">
      {/* About Banner Section Start */}
      <div className="page-top-section">
        <div className="page-pagination">
          <NavLink to="/" className="pagination-item">
            Home
          </NavLink>
          /
          <NavLink to="/about" className="pagination-item active">
            About us
          </NavLink>
        </div>
        <div className="page-title">About Us</div>
      </div>
      {/* About Banner Section End */}

      {/* About Us Section Start */}
      <section className="about-page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">About Us</div>
              <div className="about-page-title">
                Personal loans to fulfill your dreams
              </div>
              <div className="about-page-appeal">
                <img
                  className="about-page-appeal-left"
                  src={AboutIcon}
                  alt="loan icon"
                />
                <div className="about-page-appeal-Right">
                  We’re in this business since 1987 and we provide the best
                  services.
                </div>
              </div>
              <div className="about-page-text">
                We believe in making things easy for you. Our online application
                process is straightforward and can be completed in just a few
                minutes. Say goodbye to lengthy paperwork and hello to quick
                approvals. We offer a variety of loan products tailored to meet
                your unique needs. Whether you're looking for a personal loan to
                cover unexpected expenses, a home improvement loan to upgrade
                your living space.
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={AboutIllustration}
                className="about-illustration"
                alt="about illustration"
              />
            </div>
          </div>
        </div>
      </section>
      {/* About Us Section End */}

      {/* Our Offering Section Start */}
      <div className="offering-section my-5">
        <div className="container">
          <div className="row g-md-5 gy-5">
            <div className="col-12 text-center">
              <div className="section-title">What We’re Offering</div>
              <div className="offering-section-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit,
                minus aspernatur? Repellat deleniti quo laborum suscipit sequi
                similique vitae. Earum corrupti eveniet, expedita dolores
                adipisci hic, atque praesentium error consequuntur placeat
                doloremque quos reiciendis, dolore quod! Repellat nemo ad, rerum
                eaque quo accusamus doloremque harum. Voluptas dignissimos
                eligendi praesentium similique.
              </div>
            </div>
            {data.map((val, index) => {
              return (
                <div className="col-xl-4 col-md-6" key={index}>
                  <div className="offeringCard">
                    <div className="offeringCard-details">
                      <div className="offeringCard-title">{val.title}</div>
                      <div className="offeringCard-text">{val.text}</div>
                    </div>
                    <img
                      src={images[index]}
                      className="offeringCard-img"
                      alt="illustration"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Our Offering Section End */}

      {/* Testimonials Section Start */}
      <Testimonials />
      {/* Testimonials Section End */}

      {/* Get Loan Section Start */}
      <div className="mt-5">
        <GetLoan />
      </div>
      {/* Get Loan Section End */}
    </div>
  );
};

const data = [
  {
    title: "Fast Application Process",
    text: "We believe in making things easy for you. Our online application process is straightforward and can be completed in just a few minutes. Say goodbye to lengthy paperwork and hello to quick approvals.",
  },
  {
    title: "Competitive Interest Rates",
    text: "We understand the importance of affordability. Our competitive interest rates ensure that you get the financial assistance you need without breaking the bank. Transparent terms and no hidden fees.",
  },
  {
    title: "Flexible Loan Options",
    text: "We offer a variety of loan products tailored to meet your unique needs. Whether you're looking for a personal loan to cover unexpected expenses, a home improvement loan to upgrade your living space.",
  },
  {
    title: "Secure and Confidential",
    text: "Your privacy is our priority. Rest assured that your personal and financial information is treated with the utmost confidentiality and security. Our robust security measures ensure a safe borrowing experience.",
  },
  {
    title: "Dedicated Customer Support",
    text: "Have questions or need assistance? Our friendly and knowledgeable customer support team is here to help. We value your satisfaction and are committed to providing the support you deserve throughout.",
  },
  {
    title: "Financial Education Resources",
    text: "We believe in empowering our customers with knowledge. Explore our financial education resources to enhance your understanding of responsible borrowing, budgeting, and financial planning",
  },
];

export default About;
