import React from "react";
import { NavLink } from "react-router-dom";
import Img from "../../assets/about-loan.svg";
import Slider from "react-slick";
import "./_all.scss";

const Features = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="features-section" id="features">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="section-title">Our Features</div>
              <div className="features-title">
                We repair & improve your <br /> credit scores
              </div>
            </div>
          </div>
          <div className="col-12 position-relative">
            <Slider {...settings}>
              {features.map((val, index) => {
                return (
                  <div className="features-card" key={index}>
                    <img className="features-card-img" src={Img} alt="icon" />
                    <div className="features-card-num">0{index + 1}</div>
                    <div className="features-card-text">{val.title}</div>
                    <div className="features-card-description">{val.desc}</div>
                    <NavLink to={val.link} className="right-arrow">
                      <i className="fa-solid fa-angle-right"></i>
                    </NavLink>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

const features = [
  {
    title: "Home Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/home-loan",
  },
  {
    title: "Wedding Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/wedding-loan",
  },
  {
    title: "Car Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/car-loan",
  },
  {
    title: "Bussiness Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/business-loan",
  },
  {
    title: "Education Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/education-loan",
  },
  {
    title: "Personal Loan",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaearum quam dolor. Cumque odit delectus iusto. Harum reiciendis nam.",
    link: "/loan/personal-loan",
  },
];

export default Features;
