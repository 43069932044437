import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/header';
import Footer from './components/footer';
import Home from "./pages/home";
import About from "./pages/about";
import ContactUs from "./pages/contact";
import Loan from "./pages/loan";
import Policies from './pages/policies';
import AOS from "aos";

const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }, []);
  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;
    setIsVisible(scrollY > window.innerHeight / 2);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/loan/:id" element={<Loan />} />
        <Route path="/policies-and-guidelines" element={<Policies />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
      {isVisible && <i className="fa-solid fa-angle-up scroll-up" onClick={scrollToTop}></i>}
    </BrowserRouter>
  )
}

export default App;