import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import "./_all.scss";

const Footer = () => {
  const [email, setEmail] = useState("");

  const submitEvent = (e) => {
    e.preventDefault();
    setEmail("");
    toast(`Newsletter subscribed successfully`, {
      style: {
        background: "#f4f0e2",
        color: "#f2510d",
      },
    });
  };
  return (
    <section className="footer-section">
      {/* Tostify message */}
      <ToastContainer hideProgressBar={true} />

      <div className="container">
        <div className="row gx-md-5">
          <div className="col-lg-4 mb-5 mb-lg-0">
            <div className="logo">
              <span className="logo-main">TFH</span>Sales
            </div>
            <p className="footer-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
              reiciendis voluptas architecto quasi ipsam suscipit praesentium
              quo officiis aliquam voluptatum!
            </p>
            <div className="footer-detail">
              <i className="fa-solid fa-envelope"></i>tfhsales@info.com
            </div>
            <div className="footer-detail">
              <i className="fa-solid fa-phone"></i>+0000 0000 0000
            </div>
            <div className="social-accounts">
              <a
                href="https://www.facebook.com/"
                className="account"
                target="_black"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/"
                className="account"
                target="_black"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://twitter.com/"
                className="account"
                target="_black"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
              <a
                href="https://in.pinterest.com/"
                className="account"
                target="_black"
              >
                <i className="fa-brands fa-pinterest-p"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-5 mb-lg-0">
            <div className="footer-title">Quick Links</div>
            <div className="footer-links">
              <NavLink
                to="/"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Home
              </NavLink>
              <NavLink
                to="/about"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                About Us
              </NavLink>
              <NavLink
                to="/"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Sitemap
              </NavLink>
              <NavLink
                to="/contact-us"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Contact Us
              </NavLink>
              <NavLink
                to="/policies-and-guidelines"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Policies and guidelines
              </NavLink>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-5 mb-lg-0">
            <div className="footer-title">Other Links</div>
            <div className="footer-links">
              <NavLink
                to="/loan/home-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Home Loan
              </NavLink>
              <NavLink
                to="/loan/wedding-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Wedding Loan
              </NavLink>
              <NavLink
                to="/loan/car-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Car Loan
              </NavLink>
              <NavLink
                to="/loan/business-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Bussiness Loan
              </NavLink>
              <NavLink
                to="/loan/education-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Education Loan
              </NavLink>
              <NavLink
                to="/loan/personal-loan"
                className="footer-item"
                onClick={() => window.scrollTo(0, 0)}
              >
                Personal Loan
              </NavLink>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="footer-title">Newsletter</div>
            <form className="newsletter-form" onSubmit={submitEvent}>
              <input
                type="email"
                placeholder="Email address"
                required
                className="newsletter-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="newsletter-btn">
                Send
              </button>
            </form>
            <p className="footer-desc">
              I agree to all your terms and policies
            </p>
          </div>
          <div className="col-12">
            <div className="copyright-section">
              Copyright © 2023 All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
