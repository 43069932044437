import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./_all.scss";

const Header = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  return (
    <div id="header" data-aos="fade-down">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-lg-between justify-content-start">
            <div className="hemburger-menu" onClick={toggleSidebar}>
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="logo">
              <span className="logo-main">TFH</span>Sales
            </div>
            <nav className="navigation-list">
              <NavLink to="/" className="navigation-item">
                Home
              </NavLink>
              <NavLink to="/about" className="navigation-item">
                About
              </NavLink>
              <div className="navigation-item">
                Features <i className="fa-solid fa-angle-down"></i>
                <div className="navigation-submenu">
                  <NavLink
                    to="/loan/home-loan"
                    className="navigation-submenu-item"
                  >
                    Home Loan
                  </NavLink>
                  <NavLink
                    to="/loan/wedding-loan"
                    className="navigation-submenu-item"
                  >
                    Wedding Loan
                  </NavLink>
                  <NavLink
                    to="/loan/car-loan"
                    className="navigation-submenu-item"
                  >
                    Car Loan
                  </NavLink>
                  <NavLink
                    to="/loan/business-loan"
                    className="navigation-submenu-item"
                  >
                    Bussiness Loan
                  </NavLink>
                  <NavLink
                    to="/loan/education-loan"
                    className="navigation-submenu-item"
                  >
                    Education Loan
                  </NavLink>
                  <NavLink
                    to="/loan/personal-loan"
                    className="navigation-submenu-item"
                  >
                    Personal Loan
                  </NavLink>
                </div>
              </div>
              <NavLink to="/contact-us" className="navigation-item">
                Contact Us
              </NavLink>
              <NavLink
                to="/policies-and-guidelines"
                className="navigation-item"
              >
                Policies and Guidelines
              </NavLink>
              <div className="loan-calculator-btn">Loan Calculator</div>
            </nav>
            <div
              className="sideBar-container"
              onClick={toggleSidebar}
              style={{ display: sidebarVisible ? "block" : "none" }}
            >
              <div className="sideBar">
                <div className="logo">
                  <span className="logo-main">TFH</span>Sales
                </div>
                <nav className="sideBar-list">
                  <NavLink
                    to="/"
                    className="sideBar-item"
                    onClick={toggleSidebar}
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to="/about"
                    className="sideBar-item"
                    onClick={toggleSidebar}
                  >
                    About
                  </NavLink>
                  <div className="sideBar-item" onClick={toggleSidebar}>
                    Features
                    <div className="sideBar-submenu">
                      <NavLink
                        to="/loan/home-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Home Loan
                      </NavLink>
                      <NavLink
                        to="/loan/wedding-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Wedding Loan
                      </NavLink>
                      <NavLink
                        to="/loan/car-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Car Loan
                      </NavLink>
                      <NavLink
                        to="/loan/business-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Bussiness Loan
                      </NavLink>
                      <NavLink
                        to="/loan/education-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Education Loan
                      </NavLink>
                      <NavLink
                        to="/loan/personal-loan"
                        className="sideBar-submenu-item"
                        onClick={toggleSidebar}
                      >
                        Personal Loan
                      </NavLink>
                    </div>
                  </div>
                  <NavLink
                    to="/contact-us"
                    className="sideBar-item"
                    onClick={toggleSidebar}
                  >
                    Contact Us
                  </NavLink>
                  <NavLink
                    to="/policies-and-guidelines"
                    className="sideBar-item"
                  >
                    Policies and Guidelines
                  </NavLink>
                  <NavLink
                    to="/"
                    className="sideBar-item"
                    onClick={toggleSidebar}
                  >
                    Loan Calculator
                  </NavLink>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
