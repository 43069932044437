import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import GetLoan from "../../components/get-loan";
import "./_all.scss";

const Policies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policies-page">
      {/* Contact Banner Section Start */}
      <div className="page-top-section">
        <div className="page-pagination">
          <NavLink to="/" className="pagination-item">
            Home
          </NavLink>
          /
          <NavLink
            to="/policies-and-guidelines"
            className="pagination-item active"
          >
            Policies and Guidelines
          </NavLink>
        </div>
        <div className="page-title">Policies and Guidelines</div>
      </div>
      {/* Contact Banner Section End */}

      {/* Policy Details Start */}
      <div className="policy-details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Maiores est veritatis illo? Nemo ipsam debitis saepe libero ex
                assumenda sapiente rerum. Alias vitae, totam quasi vero repellat
                iure error doloribus, at, ullam eaque delectus facere architecto
                itaque cumque blanditiis! Eius distinctio placeat maiores
                voluptatum esse! Libero est molestiae reiciendis similique?
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Officiis, eos quis. Eos quod nobis perferendis aut dignissimos
                culpa quisquam iusto?
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Libero, voluptatem?
              </p>
              <p className="policy-desc">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. A,
                modi deserunt libero, molestias at corrupti nesciunt
                voluptatibus dignissimos eos aut vero reiciendis autem ab
                aliquam culpa quidem repudiandae, rem facere maxime quo! Magnam
                aliquid sequi iste, tempore laboriosam id in officiis quidem
                voluptatibus optio numquam eveniet ab incidunt explicabo dolore
                quae! Autem laborum ad amet incidunt vitae officia corporis,
                quos quis, molestias ea aspernatur qui, aliquid debitis
                obcaecati. Rerum modi pariatur aspernatur impedit aperiam,
                facere sint rem quae autem aliquid atque nobis aliquam. Maxime
                harum a reprehenderit eos qui quaerat ducimus debitis modi
                provident iure, facilis asperiores atque hic dolorum.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
                quod adipisci aperiam fuga! Voluptate sapiente vel aliquid nemo
                dicta, voluptatem explicabo veniam magni, ipsam asperiores esse
                alias, maiores sint deleniti!
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                corrupti quos nemo suscipit excepturi quibusdam labore officia
                atque dolorem dicta. Nesciunt quisquam reprehenderit,
                consequatur atque ullam quis voluptas qui repellat, officia id
                nostrum praesentium doloremque. Quisquam porro voluptate alias
                quidem?
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti, expedita!
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti, expedita!
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
                ratione minima ea accusantium sunt! Praesentium cupiditate,
                maxime quaerat, nulla iusto corporis quasi rem aspernatur, id ab
                quis eos nesciunt dolorem quia odit qui vel deleniti voluptate
                veniam impedit corrupti. Animi officiis officia veritatis,
                temporibus quasi perferendis consequatur voluptatibus maxime
                repudiandae.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
                quibusdam accusamus facere distinctio, qui assumenda cum
                molestias corrupti placeat odio illum, consequuntur quam esse,
                tempore aliquam consequatur. Quisquam quia rerum consequatur
                accusamus accusantium tenetur iure voluptas, quasi natus
                architecto nostrum ut eveniet neque reprehenderit autem corrupti
                amet voluptates facilis tempore? Asperiores aliquam quidem sequi
                non molestiae ipsam esse modi ea ad cumque labore cupiditate
                cum, et fugit perferendis quasi exercitationem.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti, expedita!
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Cupiditate eaque quia, non rerum quidem ad inventore similique
                numquam? Delectus voluptatibus iusto nulla ad quibusdam!
                Pariatur, dolore reprehenderit possimus, culpa incidunt
                consequuntur quae debitis labore quas maiores dolorum corporis
                inventore quasi repellendus nam? Aperiam aliquid inventore, in
                sequi praesentium nobis suscipit exercitationem aspernatur
                assumenda eveniet optio, porro doloremque nam harum ullam.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti, expedita!
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Officia id numquam ab consectetur ipsam, aliquam tenetur
                possimus perspiciatis quae nulla necessitatibus consequuntur!
                Aliquid quisquam amet, dolores ratione odio doloribus
                dignissimos, facilis perspiciatis corporis, impedit voluptatum
                distinctio accusantium eum? Modi labore deserunt nostrum
                explicabo aspernatur. Quae vero reiciendis minus cupiditate rem
                illo mollitia voluptates, soluta, veritatis recusandae eveniet!
                Animi consequatur maiores tempora sequi, amet perferendis
                quaerat cum voluptatum repellendus, repudiandae enim.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
                ratione minima ea accusantium sunt! Praesentium cupiditate,
                maxime quaerat, nulla iusto corporis quasi rem aspernatur, id ab
                quis eos nesciunt dolorem quia odit qui vel deleniti voluptate
                veniam impedit corrupti. Animi officiis officia veritatis,
                temporibus quasi perferendis consequatur voluptatibus maxime
                repudiandae.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Praesentium veritatis quibusdam odio cumque deleniti perferendis
                illum voluptas mollitia tempore rerum.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem
                ratione minima ea accusantium sunt! Praesentium cupiditate,
                maxime quaerat, nulla iusto corporis quasi rem aspernatur, id ab
                quis eos nesciunt dolorem quia odit qui vel deleniti voluptate
                veniam impedit corrupti. Animi officiis officia veritatis,
                temporibus quasi perferendis consequatur voluptatibus maxime
                repudiandae.
              </p>
              <p className="policy-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Deleniti, expedita!
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Policy Details End */}

      {/* Get Loan Section Start */}
      <div className="mt-5">
        <GetLoan />
      </div>
      {/* Get Loan Section End */}
    </div>
  );
};

export default Policies;
