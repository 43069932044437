import React from "react";
import User from "../../assets/user.jpg";
import Slider from "react-slick";
import "./_all.scss";

const Testimonials = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="testimonial-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="section-title">OUR TESTIMONIALS</div>
              <div className="testimonial-title text-center">
                What they’re talking about <br />
                our company
              </div>
            </div>
          </div>
          <Slider {...settings}>
            {data.map((val, index) => {
              return (
                <div className="col-4" key={index}>
                  <div className="testimonial">
                    <div className="testimonial-header">
                      <div className="testimonial-left">
                        <img
                          className="testimonial-image"
                          src={User}
                          alt="user profile"
                        />
                      </div>
                      <div className="testimonial-right">
                        <div className="user-rating">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </div>
                        <div className="user-name">{val.name}</div>
                        <div className="user-designation">
                          {val.designation}
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-content">
                      {val.desc}
                      <i className="fa-solid fa-quote-left testi-quote"></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="testimonial-highlights">
        <div className="container">
          <div className="row">
            {highlightsData.map((val, index) => {
              return (
                <div className="col-lg-3 col-sm-6" key={index}>
                  <div className="highight-data">
                    <div className="highight-number">{val.number}</div>
                    <div className="highight-text">{val.text}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

const data = [
  {
    name: "Arpit Tyagi",
    designation: "Finance Manager",
    desc: "I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.",
  },
  {
    name: "Shivank Varshney",
    designation: "Finance Manager",
    desc: "I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.",
  },
  {
    name: "Ram Kumar",
    designation: "Finance Manager",
    desc: "I loved the customer service you guys provided me. That was very nice and patient with questions I had. I would really like definitely come back here. Thank you for yours service.",
  },
];

const highlightsData = [
  {
    number: "95%",
    text: "Loans Approve",
  },
  {
    number: "$95k",
    text: "Daily Payments",
  },
  {
    number: "50k",
    text: "Happy Customers",
  },
  {
    number: "400",
    text: "Expert People",
  },
];

export default Testimonials;
