import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AboutIllustration from "../../assets/about.jpg";
import BenifitIllustration from "../../assets/benifit.jpg";
import AboutIcon from "../../assets/about-loan.svg";
import GetLoan from "../../components/get-loan";
import Testimonials from "../../components/testimonials";
import Features from "../../components/features";
import LoanApply from "../../components/loan-apply/index";
import Faq from "../../components/faq";
import "./_all.scss";

const Home = () => {
  const [formVisible, setformVisible] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home">
      <LoanApply formVisible={formVisible} setformVisible={setformVisible} />
      {/* Home Section Start */}
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center text-white">
                <div className="banner-sub-text">
                  Get the Funds You Need, When You Need Them
                </div>
                <div className="banner-title text-center">
                  Empowering Your Dreams <br /> Hassle-Free Loans at Your
                  Fingertips
                </div>
                <div className="banner-text text-center">
                  Welcome to <span className="site-name">TFH Sales</span>, your
                  trusted partner in financial empowerment. At
                  <span className="site-name"> TFH Sales</span>, we understand
                  that life's journey is filled with unexpected twists and
                  turns, and sometimes you need a helping hand to navigate
                  through them. That's where we come in, offering a seamless and
                  reliable platform to fulfill your financial needs.
                </div>
                <div className="banner-btn">
                  <a className="out-services" href="#features">
                    Our Services
                  </a>
                  <div
                    className="apply-now"
                    onClick={() => setformVisible(true)}
                  >
                    Apply Now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Section End */}

      {/* About Section Start */}
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="section-title">About Company</div>
              <div className="about-section-title">
                Personal loans to fulfill your dreams
              </div>
              <div className="about-section-appeal">
                <img
                  className="about-section-appeal-left"
                  src={AboutIcon}
                  alt="loan icon"
                />
                <div className="about-section-appeal-Right">
                  We’re in this business since 1987 and we provide the best
                  services.
                </div>
              </div>
              <div className="about-section-text">
                We believe in making things easy for you. Our online application
                process is straightforward and can be completed in just a few
                minutes. Say goodbye to lengthy paperwork and hello to quick
                approvals.
              </div>
              <NavLink to="/about" className="about-section-btn">
                Discover More
              </NavLink>
            </div>
            <div className="col-xl-6 mt-5 mt-xl-0">
              <img
                src={AboutIllustration}
                className="about-illustration"
                alt="about illustration"
              />
            </div>
          </div>
        </div>
      </section>
      {/* About Section End */}

      {/* Features Section Start */}
      <Features />
      {/* Features Section End */}

      {/* Testimonials Section Start */}
      <Testimonials />
      {/* Testimonials Section End */}

      {/* Benifit Section Start */}
      <section className="benifit-section">
        <div className="container">
          <div className="row gx-md-5">
            <div className="col-xl-6">
              <img
                src={BenifitIllustration}
                className="benifit-illustration mb-xl-0 mb-5"
                alt="about illustration"
              />
            </div>
            <div className="col-xl-6">
              <div className="section-title">OUR BENEFITS</div>
              <div className="benifit-section-title">
                Why you should choose our company
              </div>
              <div className="benifit-section-text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Doloribus ut quidem odit ducimus reiciendis laudantium, fugit at
                cum natus maxime aut tenetur accusamus quis, repellat distinctio
                eveniet. Nisi, omnis! Est reprehenderit totam suscipit debitis
                itaque maxime blanditiis nesciunt sint, pariatur exercitationem
                tempore.
              </div>
              <div className="benifit-section-appeal">
                We’re in this business since 1987 and we provide the best
                services.
              </div>
              <ul className="benifit-section-list">
                <li className="benifit-section-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. In
                    ex iste cumque perferendis officia hic saepe amet ab sunt.
                    Et!
                  </div>
                </li>
                <li className="benifit-section-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Ipsa, quis?
                  </div>
                </li>
                <li className="benifit-section-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea
                    earum quam dolor. Cumque odit delectus iusto. Harum
                    reiciendis nam, cumque quod necessitatibus quidem adipisci
                    distinctio expedita unde dolores, modi hic.
                  </div>
                </li>
                <li className="benifit-section-listItem">
                  <div className="text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea
                    earum quam dolor. Cumque odit delectus iusto. Harum
                    reiciendis nam, cumque quod necessitatibus quidem adipisci
                    distinctio expedita unde dolores, modi hic.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Benifit Section End */}

      {/* Faq Section Start */}
      <div className="">
        <Faq />
      </div>
      {/* Faq Section End */}

      {/* Get Loan Section Start */}
      <div className="mt-5">
        <GetLoan />
      </div>
      {/* Get Loan Section End */}
    </div>
  );
};

export default Home;
