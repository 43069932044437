import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./_all.scss";

const LoanApply = ({ formVisible, setformVisible }) => {
  const [data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    number: "",
    loanType: "",
    companyName: "",
    companyType: "",
    pincode: "",
    state: "",
    city: "",
    occupation: "",
  });
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const submitEvent = (e) => {
    e.preventDefault();
    setformVisible(false);
    console.log(data);
    setData({
      fname: "",
      lname: "",
      email: "",
      number: "",
      loanType: "",
      companyName: "",
      companyType: "",
      pincode: "",
      state: "",
      city: "",
      occupation: "",
    });
    toast(`Applied successfully`, {
      style: {
        background: "#f4f0e2",
        color: "#f2510d",
      },
    });
  };
  return (
    <>
      {formVisible && (
        <div className="loan-apply-container">
          <ToastContainer hideProgressBar={true} />
          <form className="loan-apply" onSubmit={submitEvent}>
            <i
              class="fa-regular fa-circle-xmark cross-mark"
              onClick={() => setformVisible(false)}
            ></i>
            <h1 className="loan-apply-heading">Personal Details:</h1>
            <div className="row">
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    First Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="field-input"
                    placeholder="Enter your first name"
                    required
                    name="fname"
                    value={data.fname}
                    onChange={inputEvent}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Last Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    className="field-input"
                    placeholder="Enter your last name"
                    required
                    name="lname"
                    value={data.lname}
                    onChange={inputEvent}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Email Id
                  </label>
                  <input
                    type="email"
                    className="field-input"
                    placeholder="Enter your email id"
                    onChange={inputEvent}
                    name="email"
                    value={data.email}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Mobile Number<span>*</span>
                  </label>
                  <div className="mobile-field">
                    <div className="prefix">+91</div>
                    <input
                      type="text"
                      className="field-input"
                      placeholder="Enter your mobile number"
                      required
                      name="number"
                      value={data.number}
                      onChange={inputEvent}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Loan Type
                  </label>
                  <select
                    name="loanType"
                    value={data.loanType}
                    onChange={inputEvent}
                    className="field-input"
                  >
                    <option value="Home Loan">Home Loan</option>
                    <option value="Wedding Loan">Wedding Loan</option>
                    <option value="Car Loan">Car Loan</option>
                    <option value="Business Loan">Business Loan</option>
                    <option value="Education Loan">Education Loan</option>
                    <option value="Personal Loan">Personal Loan</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="field-input"
                    placeholder="Enter your company name"
                    name="companyName"
                    value={data.companyName}
                    onChange={inputEvent}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Company Type
                  </label>
                  <select
                    name="companyType"
                    value={data.companyType}
                    onChange={inputEvent}
                    id=""
                    className="field-input"
                  >
                    <option value="Public Limited">Public Limited</option>
                    <option value="Private Limited">Private Limited</option>
                    <option value="Government">Government</option>
                    <option value="Propritorship">Propritorship</option>
                    <option value="Partneship">Partneship</option>
                    <option value="Limited Liability Partnership">
                      Limited Liability Partnership
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="field-input"
                    placeholder="Enter your pincode"
                    name="pincode"
                    value={data.pincode}
                    onChange={inputEvent}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    State
                  </label>
                  <select
                    name="state"
                    value={data.state}
                    onChange={inputEvent}
                    className="field-input"
                  >
                    {states.map((val, index) => {
                      return (
                        <option value={val} key={index}>
                          {val}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Enter City
                  </label>
                  <input
                    type="text"
                    className="field-input"
                    placeholder="Enter your city name"
                    name="city"
                    value={data.city}
                    onChange={inputEvent}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="field-group">
                  <label htmlFor="" className="field-label">
                    Occupation<span>*</span>
                  </label>
                  <div className="radio-option">
                    <div className="radio-item">
                      <input
                        type="radio"
                        name="occupation"
                        value="Salaried"
                        id="Salaried"
                        required
                        onChange={inputEvent}
                      />
                      <label htmlFor="Salaried">Salaried</label>
                    </div>
                    <div className="radio-item">
                      <input
                        type="radio"
                        name="occupation"
                        value="Self-Employed"
                        id="Self-Employed"
                        required
                        onChange={inputEvent}
                      />
                      <label htmlFor="Self-Employed">Self-Employed</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="loan-apply-form" type="submit">
              Apply
            </button>
          </form>
        </div>
      )}
    </>
  );
};

const states = [
  "Andhra Pradesh",
  "Tamil Nadu",
  "Gujarat",
  "Karnataka",
  "Madhya Pradesh",
  "Bihar",
  "Maharashtra",
  "Rajasthan",
  "Uttar Pradesh",
  "Haryana",
  "Kerala",
  "Odisha",
  "Punjab",
  "West Bengal",
  "Arunachal Pradesh",
  "Jharkhand",
  "Assam",
  "Himachal Pradesh",
  "Goa",
  "Sikkim",
  "Nagaland",
  "Meghalaya",
  "Mizoram",
  "Uttarakhand",
];

export default LoanApply;
